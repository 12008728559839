var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{directives:[{name:"dialogDrag",rawName:"v-dialogDrag"},{name:"loading",rawName:"v-loading",value:(_vm.dialogLoading),expression:"dialogLoading"}],attrs:{"title":_vm.editType == 2 ? '编辑' : '新增',"visible":_vm.addVisible,"width":"700px","close-on-click-modal":false,"element-loading-background":_vm.loadingBackground,"before-close":_vm.handleClose,"custom-class":"install-scene-dialog"}},[_c('el-form',{ref:"addForm",attrs:{"label-width":"120px","model":_vm.addForm,"rules":_vm.addFormRules}},[_c('el-form-item',{attrs:{"label":"单据类型","prop":"consumeType"}},[_c('e6-vr-select',{attrs:{"data":_vm.consumeTypeList,"placeholder":"单据类型","title":"单据类型","props":{
          id: 'codeValue',
          label: 'codeName'
        },"clearable":""},on:{"change":_vm.changeType},model:{value:(_vm.addForm.consumeType),callback:function ($$v) {_vm.$set(_vm.addForm, "consumeType", $$v)},expression:"addForm.consumeType"}})],1),_vm._v(" "),_c('el-form-item',{attrs:{"label":"工单类型","prop":"workOrderTypeList"}},[_c('e6-vr-select',{attrs:{"data":_vm.consumeMaterialWorkTypeList,"placeholder":"工单类型","title":"工单类型","clearable":"","props":{
          id: 'codeValue',
          label: 'codeName'
        },"multiple":""},model:{value:(_vm.addForm.workOrderTypeList),callback:function ($$v) {_vm.$set(_vm.addForm, "workOrderTypeList", $$v)},expression:"addForm.workOrderTypeList"}})],1),_vm._v(" "),_c('el-form-item',{attrs:{"label":"是否启用","prop":"status"}},[_c('el-switch',{attrs:{"active-value":1,"inactive-value":0},model:{value:(_vm.addForm.status),callback:function ($$v) {_vm.$set(_vm.addForm, "status", $$v)},expression:"addForm.status"}})],1)],1),_vm._v(" "),_c('div',[_c('el-table',{ref:"selectTable",attrs:{"border":"","data":_vm.tableData,"highlight-current-row":""}},[_vm._l((_vm.columnData),function(column,index){return _c('el-table-column',{key:index,attrs:{"show-overflow-tooltip":"","prop":column.fieldName,"label":column.fieldLabel,"min-width":column.width,"align":column.align,"header-align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var row = ref.row;
return [(column.fieldName === 'materialNo')?_c('div',[_c('remote-search',{attrs:{"remote":true,"clearable":"","placeholder":"耗材名称","title":"耗材名称","searchData":{
                materialTypes: [408702, 408703],
                pageIndex: 1,
                pageSize: 200
              },"defaultValue":_vm.editType == 1
                  ? {}
                  : {
                      materialName: row.materialNoName,
                      materialNo: row.materialNo
                    },"showInit":_vm.editType == 1 ? true : false},on:{"change":function (val, node) {
                  _vm.changeMaterialNo(val, node, row);
                }},model:{value:(row.materialNo),callback:function ($$v) {_vm.$set(row, "materialNo", $$v)},expression:"row.materialNo"}})],1):(column.fieldName === 'amount')?_c('div',[_c('el-input',{model:{value:(row.amount),callback:function ($$v) {_vm.$set(row, "amount", $$v)},expression:"row.amount"}})],1):(column.fieldName === 'bizNoList	 ')?_c('div',[_c('e6-vr-select',{attrs:{"data":_vm.classifyList,"placeholder":"物料分类","title":"物料分类","clearable":"","props":{
                id: 'classifyNo',
                label: 'classifyName'
              },"multiple":""},model:{value:(row.bizNoList),callback:function ($$v) {_vm.$set(row, "bizNoList", $$v)},expression:"row.bizNoList"}})],1):_c('span',[_vm._v(_vm._s(row[column.fieldName]))])]}}],null,true)})}),_vm._v(" "),_c('el-table-column',{attrs:{"label":"操作","show-overflow-tooltip":"","align":"center","header-align":"center","min-width":"90"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var row = ref.row;
              var $index = ref.$index;
return [_c('i',{staticClass:"e6-icon-add_line1",attrs:{"title":"新增"},on:{"click":function($event){return _vm.handleAdd(row, $index)}}}),_vm._v(" "),_c('i',{staticClass:"e6-icon-clear_fill",attrs:{"title":"删除"},on:{"click":function($event){return _vm.deleteColumn($index, row)}}})]}}])})],2)],1),_vm._v(" "),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{staticClass:"cancel",on:{"click":_vm.handleClose}},[_vm._v("取消")]),_vm._v(" "),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.confirm}},[_vm._v("确定")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }